<template>
  <!-- detail delivery orders -->
  <div class="w-full vx-row">
    <vs-table
      :style="{ width: '100%' }"
      :sst="false"
      :data="dataViews"
      :total="dataViews.length"
      :searchable="false"
    >
      <template slot="thead">
        <vs-th>Delivery Order Code</vs-th>
        <vs-th>Sales Order Code</vs-th>
        <vs-th>Customer Name</vs-th>
        <vs-th>Customer Code</vs-th>
        <vs-th>Status Delivery</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tk" :key="indextr" v-for="(tk, indextr) in data">
          <vs-td>
            {{ tk.delivery_order_code }}
          </vs-td>
          <vs-td>
            {{ tk.sales_order_code }}
          </vs-td>
          <vs-td>
            {{ tk.customer_name }}
          </vs-td>
          <vs-td>
            {{ tk.customer_code }}
          </vs-td>
          <vs-td>
            {{ tk.status_delivery == 0 ? "Not Delivered" : "Delivered" }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row mt-2 w-full">
      <span class="w-1/3"></span>
      <div class="w-2/3">
        <vs-button v-on:click="submit()" class="w-1/3" color="danger"
          >Force End Session</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { icon } from "leaflet";
import moment from "moment";

export default {
  props: {
    dataViews: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => {},
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getTransportPlan();
  },
  watch: {
    endSession() {
      if (this.endSession) {
        this.submit();
      }
    },
  },
  data() {
    return {
      dateTp: moment().format("YYYY-MM-DD"),
      dateNow: moment().format("YYYY-MM-DD"),
      transportPlan: [],
      transportPlanSelected: null,
      endSession: false,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Are you sure to EndSession this Shipment?",
            accept: () => {
              this.SubmitForm();
            },
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Please complete the form",
            color: "warning",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      });
    },

    SubmitForm() {
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/proof-of-delivery/end-session", {
          data_end_session: this.dataViews,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$emit("close");
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
  },
};
</script>
<style>
.vl {
  border-left: 6px solid black;
  height: 100%;
}
</style>
